<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Nominaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Nominaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-10">
                    <h5>Nominaciones</h5>
                  </div>
                  <div class="col-md-2">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-tepNominacion"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        v-if="$store.getters.can('tep.nominaciones.create')"
                        @click="save()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <table
                  id="ofertas"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th class="text-center">
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        Sitio
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.sitio"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        Empresa
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.empresa"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Inicial
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_ini"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Final
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_fin"
                          @change="getIndex()"
                        />
                      </th>
                      <th class="text-center">Observación</th>
                      <th class="text-center">
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="nominacion in nominaciones.data"
                      :key="nominacion.id"
                    >
                      <td>{{ nominacion.id }}</td>
                      <td class="text-nowrap">{{ nominacion.nombre }}</td>
                      <td>{{ nominacion.sitio.nombre }}</td>
                      <td>
                        {{
                          nominacion.tep_det_nominacion.length > 0
                            ? nominacion.tep_det_nominacion[0]
                                .tep_transportadora.razon_social
                            : "Sin asignar"
                        }}
                      </td>
                      <td class="text-center">
                        {{ nominacion.fecha_ini }}
                      </td>
                      <td class="text-center">
                        {{ nominacion.fecha_fin }}
                      </td>
                      <td class="text-wrap text-xs">
                        {{ nominacion.observacion }}
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            nominacion.estado == 1
                              ? 'badge-warning'
                              : 'badge-success'
                          "
                          >{{ nominacion.nEstado }}</span
                        >
                      </td>
                      <td style="width: 50px" class="text-left">
                        <div class="btn-group float-left">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="$store.getters.can('tep.nominaciones.show')"
                            @click="save(nominacion)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              $store.getters.can('tep.nominaciones.delete') &&
                                nominacion.estado != 2
                            "
                            @click="destroy(nominacion)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="nominaciones.total">
                  <p>
                    Mostrando del <b>{{ nominaciones.from }}</b> al
                    <b>{{ nominaciones.to }}</b> de un total:
                    <b>{{ nominaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="nominaciones"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <TepNominacionExport ref="TepNominacionExport" />
  </div>
</template>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import TepNominacionExport from "./TepNominacionExport";

export default {
  name: "NominacionIndex",
  components: {
    pagination,
    Loading,
    TepNominacionExport,
  },
  data() {
    return {
      nominaciones: {},
      filtros: {
        nombre: null,
        sitio: null,
        empresa: null,
        fecha_ini: null,
        fecha_fin: null,
      },
      listasForms: {
        estados: {},
      },
      cargando: false,
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/tep/nominaciones?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.nominaciones = response.data.data;
          this.cargando = false;
        });
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    save(nominacion = null) {
      let accion = "Crear";
      if (nominacion) {
        accion = "Editar";
      }
      return this.$router.push({
        name: "/tep/NominacionForm",
        params: {
          accion,
          nominacion,
        },
      });
    },

    destroy(nominacion) {
      this.$swal({
        title:
          "Esta seguro de eliminar la Nominación: " + nominacion.nombre + "?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios.delete("/api/tep/nominaciones/" + nominacion.id).then(() => {
            this.cargando = false;
            this.$swal({
              icon: "success",
              title: "Se eliminó la nominación exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.getIndex();
          });
        }
      });
    },
  },
  mounted() {
    this.getIndex();
    this.getEstados();
  },
};
</script>
